<template>
  <session-activity></session-activity>
</template>

<script>
import SessionActivity from "@/view/components/Sessions/SessionActivity";

export default {
  components: {
    SessionActivity,
  },
};
</script>